/* Reducir tamaño del texto en pantallas pequeñas */
@media only screen and (max-width: 600px) {
	.MuiButton-root {
		font-size: 0.8rem; /* Ajustar el tamaño del texto en los botones */
	}

	.MuiTypography-root {
		font-size: 1rem; /* Ajustar el tamaño del texto del título */
	}

	.MuiIconButton-root {
		font-size: 20px; /* Ajustar el tamaño del icono */
	}
}

.coming-soon-container {
	position: relative;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

.video-background {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -1;
	background-size: cover;
	display: block;
}

.fondo-imagen {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -2; /* Para que esté debajo del contenido */
}

.content {
	position: relative;
	z-index: 1;
	text-align: center;
	color: white;
	font-family: Arial, sans-serif;
	padding: 20px;
}

h1 {
	font-size: 3em;
	margin-bottom: 20px;
}

p {
	font-size: 1.5em;
}
